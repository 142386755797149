import React from 'react'
import { graphql } from 'gatsby'
// import { Helmet } from 'react-helmet'
// import get from 'lodash/get'
import Layout from '../components/layout'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS} from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import SEO from "../components/seo"
import './exhibit.css'
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';

class ExhibitTemplate extends React.Component {
 
  renderMainInfo = (exhibitionInformation) => {      
    if(exhibitionInformation != null)
      {
        let info = renderRichText(exhibitionInformation)
        return <div className="info-cont">{info}</div>
      }
  }

  renderPress = (press, options) => {    
    if(press != null)
    {
      const pressRichText = renderRichText(press, options)
        return <div className="press-cont">
                  Press:
                  {pressRichText}
                </div>
    }
  }

  renderImages = (works) => {
      if(works != null){
        return works.map((image) => 
          {
            if (image.photo != null) 
              {
                if(image.photo.gatsbyImageData != null)
                    {
                    return <div className={image.photo.gatsbyImageData.height>image.photo.gatsbyImageData.width? 'exhibit-image-cont-vertical':'exhibit-image-cont'} key={image.id}>  
                          <a className="lightBoxImage" href={getImage(image.photo).images.fallback.src}>
                            <GatsbyImage 
                              loading="lazy"
                              image={getImage(image.photo)}
                              alt={image.photo.description.length === 0? 
                                image.artworkInformation === null? 
                                "Installation shot": 
                                documentToPlainTextString(JSON.parse(image.artworkInformation.raw)): image.photo.description}
                              
                              className='show-image-cont' 
                              role='button'
                            />
                          </a>
                        <div className="description-image-cont">
                          {image.artworkInformation === null? "":renderRichText(image.artworkInformation)}
                        </div>
                      </div>
                      }
                
              }
            }
        )
      } 
    }

  renderCredits = (credits) => {
      if(credits != null){
        return <div>{renderRichText(credits)}</div>
      }
    }


  render() {
        const { 
          title, works, mainImage, press, pressReleaseRichText, 
           exhibitionInformation, credits} = this.props.data.contentfulExhibition
    
        const options = {
            renderNode: {
              [INLINES.ASSET_HYPERLINK]: (node, children) => {
                return <a href={node.data.target.file.url} target="_blank" rel="noreferrer" className="">{children}</a>
              },
              [INLINES.HYPERLINK]: (node, children) => {
                return <a href={node.data.uri} target="_blank" rel="noreferrer" className="">{children}</a>
              },
              [BLOCKS.EMBEDDED_ASSET]: node => {
                return <img src={node.data.target.file.url} alt=""/>
              },
              
          }
        }
        
        const output = pressReleaseRichText === null? "": renderRichText(pressReleaseRichText, options) 
     
      
    return (
      
        <Layout location={this.props.location}>
  
          <SEO 
            title={title}
            description={documentToPlainTextString(JSON.parse(exhibitionInformation.raw))}
          />
          <LightGallery
                selector=".lightBoxImage"
                download={false}
                counter={false}
                plugins={[lgZoom]}
              >
          <h1 className="visibility-hidden">{`Exhibition: ${title}`}</h1>
          
            {this.renderMainInfo(exhibitionInformation)}
            {mainImage == null? "":
            <div className="main-exhibit-image">
              {/* Main exhibition image */}
              
                <a className="lightBoxImage" href={getImage(mainImage).images.fallback.src} role='button'>
                  <GatsbyImage role='button' image={getImage(mainImage)} loading="eager" alt={mainImage.description} onClick={() => this.setState({ currentImageIndex: 0 , isOpen: true  })}/>
                </a>
            </div>}
            {this.renderPress(press, options)}  
            <div className="exhibition-press-release">{output}</div>
              {/* Images of exhibition */}
              {works == null? "":this.renderImages(works, mainImage)}
              
            {this.renderCredits(credits)}

            </LightGallery>
          
        </Layout>
    )
  }
}

export default ExhibitTemplate

export const pageQuery = graphql
`
query exhibitquery($slug: String!){
  contentfulExhibition(slug: {eq: $slug}) {
  
    artist
    exhibitionInformation {
      raw
    }
    press {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          file {
            url
            contentType
          }
        }
      }
    }
    pressReleaseRichText {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          file {
            url
            contentType
          }
        }
      }
    }
    slug
    title
    mainImage {
      description
      gatsbyImageData(
        quality: 70
        width:2000
        )
    }
    works {
      id
      year
      title
      size
      medium
      photo {
        description
        gatsbyImageData(
          quality: 70
          width: 2000
          )
      }
      artworkInformation {
        raw
      }
    }
    credits{
      raw
    }
  }
  }
  `